import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery } from "gatsby"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Sidebar from "../../components/major-pieces/sidebar/Sidebar"
import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"
import PDF from "../../../static/pdf/resources/The_Life_of_our-Holy_Mother_Mary_of_Egypt.pdf" 

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/resources.css"


function Resources () {
	
	const query = graphql`
	      query {
	        allFile(
	          sort: { fields: name, order: ASC }
	          filter: { relativePath: { glob: "images/pages/resources/*.jpg" } }
	        ) {
	          edges {
	            node {
	              id
	              name
	              childImageSharp {
	                fluid {
	                  ...GatsbyImageSharpFluid_noBase64
	                }
	              }
	            }
	          }
	        }
			}
	`


	const images = useStaticQuery(query).allFile
	const stMaryImage = images.edges.find(element => element.node.name === 'saint_mary').node

	return(
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content resources">
				<div className="services-image-wrapper">
					<Img 		           		
						fluid={stMaryImage.childImageSharp.fluid}
	 					className="services-image" alt="Logo" 
	 				/>

				</div>
				<h2>Saint Mary of Egypt</h2>
				<p>The Orthodox Church remembers Saint Mary of Egypt on April 1 and on the 5th Sunday in Great Lent. Her life story is also read aloud at Thursday Compline in the 5th week. Below is a recording of her story, Jennifer Medders reads Part 1; and Stan Snider reads Part 2. <a href={PDF} target="_blank">Here is a link to the text.</a> Below are also links to two reflections on her life that may provide you with deeper perspectives on her story.</p>
				
								<div className="recording-iframe-wrapper first-recording">
									<iframe title="Saint Mary of Egypt Part 1" height="122" width="100%"  scrolling="no" data-name="pb-iframe-player" src="https://www.podbean.com/media/player/37h87-d7edf3?from=yiiadmin&download=1&version=1&skin=1&btn-skin=107&auto=0&download=1&pbad=1"></iframe>
								</div>

								<div className="recording-iframe-wrapper">
									<iframe title="Saint Mary of Egypt Part 2" height="122" width="100%"  scrolling="no" data-name="pb-iframe-player" src="https://www.podbean.com/media/player/wqq4c-d7ee11?from=yiiadmin&download=1&version=1&skin=1&btn-skin=107&auto=0&download=1&pbad=1"></iframe>




								</div>
				<p>Audio Recordings by Jennifer Medders and Stan Snider.</p>
				<p><a href="https://blogs.ancientfaith.com/easternchristianinsights/2016/04/17/humble-repentance-paralyzing-guilt-homily-st-mary-egypt-5th-sunday-lent-orthodox-church/" target="_blank">Homiletical Reflection by Rev. Dr. Philip LeMasters</a></p>
				<p><a href="https://publicorthodoxy.org/2020/03/31/depth-psychology-and-courage-of-st-mary/" target="_blank">Psychological/Theological Reflection by Dr. Pia Sophia Chaudhari</a></p>
			</main>
			<Footer />		
		</div>
		)
}

export default Resources 
